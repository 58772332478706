<script>
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([]);

import Layout from "./../layouts/main";
import appConfig from "./../../app.config.json";
import PortfolioAssets from "@/components/dashboard/PortfolioAssets";
// import Bot from "@/state/entities/bot";

export default {
  components: {
    PortfolioAssets: PortfolioAssets,
    Layout,
  },
  page: {
    title: "Portfolio assets",
    meta: [{ name: "Portfolio assets", content: appConfig.description }],
  },
  data() {
    return {
    };
  },

  computed: {
  },
};
</script>

<template>
  <Layout>

    <div class="row" id="my-assets">
      <div class="col-xxl-12">
        <PortfolioAssets></PortfolioAssets>
      </div>
    </div>

  </Layout>
</template>
