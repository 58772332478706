<script>
import animationData from "@/components/widgets/msoeawqm.json";
import DealService from "@/services/deal-service";
import ListItem from "@/components/deals/list-item";
import CoinInfoFetcherService from "@/services/CoinInfoFetcherService";
import Coin from "@/state/entities/coin";
import Deal from "@/state/entities/deal";
import Bot from "@/state/entities/bot";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";

export default {
  name: 'PortfolioAssets',
  components: {
    ListItem
  },
  mounted(){
    document.getElementById('closeActiveDealModal').addEventListener("hidden.bs.modal", this.cancelActiveDealModal)
  },
  data() {
    return {
      updateAssetsIntervalInSeconds: 10,
      timestamp: null,
      timer: null,
      defaultOptions: { animationData: animationData },
      dataLoaded: false,
      sellingPriceUpdateTimer: 5,
      sellingPriceUpdateInterval: null,
      actualDealForSelling: null,
      actualSettingsForDeal: null,
      openModalDeal: false,
      openModalDealSettings: false,
      statisticsDate: null,
      rangeDateconfig: {
        mode: "range",
        altInput: false,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
      },
      purchaseStatisticsChart: {
        series: [1,2,3,4],
        chartOptions: {
          labels: [1,2,3,4],
          chart: {
            type: "donut",
            height: 210,
          },
          plotOptions: {
            pie: {
              size: 100,
              offsetX: 0,
              offsetY: 0,
              donut: {
                size: "70%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "18px",
                    offsetY: -5,
                  },
                  value: {
                    show: true,
                    fontSize: "20px",
                    color: "#343a40",
                    fontWeight: 500,
                    offsetY: 5,
                    formatter: function (val) {
                      return "$" + val;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "13px",
                    label: this.$t('t-total-value'),
                    color: "#9599ad",
                    fontWeight: 500,
                    formatter: function (w) {
                      return (
                          "$" +
                          parseFloat(w.globals.seriesTotals.reduce(function (a, b) {
                            return a + b;
                          }, 0)).toFixed(2)
                      );
                    },
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return "$" + value;
              },
            },
          },
          stroke: {
            lineCap: "round",
            width: 2,
          },
          colors: ['#F47A1F', '#FDBB2F', '#377B2B', '#7AC142', '#007CC3', '#00529B']
        },
      },
      form: {
        max_safety_orders: '',
      }
    };
  },
  async beforeMount() {
    await Deal.deleteAll();
    await this.fetchBots();
    await this.fetchActiveDeals();

    this.timer = setInterval(this.fetchActiveDeals, this.updateAssetsIntervalInSeconds * 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  computed: {
    spotAssets() {
      return Deal.query().with(["bot", "coin"]).orderBy('created_at', 'desc').all().filter(i=>i.market_type==='spot');
    },
    rows() {
       return Deal.query().with(["bot", "coin"]).orderBy('created_at', 'desc').all();
    },
    /** @TODO duplicated */
    isMobile() {
      return screen.availWidth < 700
    }
  },
  methods: {
    async fetchActiveDeals () {
      await DealService.activeDeals();
      this.setUpdatedTime();
      await this.fetchCoins();
      this.dataLoaded = true;
    },
    async fetchCoins() {
      for (const row of this.rows) {
        let storageCoin = localStorage.getItem(row.quote_asset.toUpperCase());
        if (!storageCoin) {
          let coinInfoResponse = await CoinInfoFetcherService.coinInfo(row.quote_asset.toUpperCase())
          storageCoin = JSON.stringify(coinInfoResponse.data);
          localStorage.setItem(coinInfoResponse.data.code, storageCoin);
        }
        let coinInfo = JSON.parse(storageCoin);
        const coin = new Coin();
        coin.title = coinInfo.title;
        coin.code = coinInfo.code;
        coin.image_path = coinInfo.image_path;
        await coin.$save();
      }
    },
    parseCreatedFilter(dates) {
      if(dates.length === 2) {
        this.filterDates = dates;
      }
    },
    async fetchBots() {
      await Bot.api().get('/bot');
    },
    setUpdatedTime: function() {
      const today = new Date();
      const date = today.toJSON().slice(0,10).replace(/-/g,'.');
      let minutes = today.getMinutes().toString().length == 1? '0' + today.getMinutes():today.getMinutes();
      let seconds = today.getSeconds().toString().length == 1? '0' + today.getSeconds():today.getSeconds();
      const time = today.getHours() + ':' + minutes + ':' + seconds;
      this.timestamp = date + ' ' + time;
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    countdownTimer() {
      clearInterval(this.sellingPriceUpdateInterval);
      this.sellingPriceUpdateInterval = setInterval( () => {
        if (this.sellingPriceUpdateTimer === 0) {
          clearInterval(this.sellingPriceUpdateInterval)
          this.sellingPriceUpdateTimer = 5;
          if (this.actualDealForSelling !== null) {
            this.openDealModal(this.rows.find(i=>i._id===this.actualDealForSelling._id))
          }
        } else {
          this.sellingPriceUpdateTimer--;
        }
      }, 1000)
    },
    cancelActiveDealModal() {
      this.sellingPriceUpdateTimer = 5;
      clearInterval(this.sellingPriceUpdateInterval)
      console.log('modal closed')
    },
    closeDeal(deal) {
      this.openModalDeal = false;
      DealService.close(deal._id).then((response) => {
        if(response.status === 201) {
          Swal.fire({
            title: this.$t("t-asset-successfully-sold"),
            icon: 'success',
            timer: 3000,
            timerProgressBar: true,
          });
        }
      })
    },

    openDealModal(Deal) {
      this.openModalDeal = true;
      this.actualDealForSelling = Deal;
      this.countdownTimer();
    },
    openDealSettingsModal(Deal) {
      this.openModalDealSettings = true;
      this.actualSettingsForDeal = Deal;
      this.form.max_safety_orders = Deal.max_safety_orders;
    },
    updateDealSettings() {
      this.openModalDealSettings = false;
      let settings = this.form;
      let deal = this.actualSettingsForDeal;

      DealService.updateSettings(deal._id, settings).then((response) => {
        if(response.status === 200) {
          Swal.fire({
            title: this.$t("t-deal-settings-updated"),
            icon: 'success',
            timer: 3000,
            timerProgressBar: true,
          });
        }
      });
    },
  },
}
</script>

<template>

  <div class="row">
    <div class="col-lg-5">
      <div class="card-header align-items-center" style="padding: 0.5rem 0rem;">
        <div class="col-12">
          <h4 class="card-title mb-0 text-center">{{ $t("t-your-assets") }}</h4>
        </div>
        <div class="col-12 text-center">
          <div class="btn-group" role="group" aria-label="Basic example">
            <div v-show="spotAssets.length == 0 && dataLoaded == false" class="spinner-border text-danger" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="text-muted" v-if="timestamp != null">
              <span>{{ $t("t-last-update") }}:</span> <span>{{ timestamp }}</span>
            </div>
          </div>
        </div>
      </div>
      <list-item v-for="(deal, index) of spotAssets" :deal="deal" :key="index" @open-deal-modal-mobile-event="openDealModal" @open-deal-settings-event="openDealSettingsModal"></list-item>
    </div>
  </div>
  <div class="card-body">
    <div class="live-preview">
      <div>
        <b-modal v-model="openModalDeal" ref="dealModal" id="closeActiveDealModal" hide-footer class="modal fade close-active-deal-modal" tabindex="-1" role="dialog"
             aria-labelledby="mySmallModalLabel" aria-hidden="true">
                <div class="mt-4" v-if="this.actualDealForSelling">
                  <h2 class="text-center">
                    <img :src="actualDealForSelling ? actualDealForSelling.coin.image_path : ''" alt="" class="avatar-xxs me-2" />
                    <span>{{ actualDealForSelling ? actualDealForSelling.coin.title : '' }}</span>
                  </h2>
                  <p class="text-muted mb-3 text-center" v-if="actualDealForSelling.profit">
                    {{ $t("t-ad-profit") }}:
                    <span class="fs-13 mb-0" :class="{
                      'text-danger': actualDealForSelling ? actualDealForSelling.profit.baseValue < 0 : false ,
                      'text-success': actualDealForSelling ? actualDealForSelling.profit.baseValue >= 0: false,
                    }">
                    {{ formatPrice(actualDealForSelling? actualDealForSelling.profit.baseValue: 0) }}
                    <small>
                      {{ actualDealForSelling? actualDealForSelling.profit.baseQuote: '' }}
                    </small>

                    ({{ actualDealForSelling? actualDealForSelling.profit.percentage: '' }}%)

                      <i class="mdi align-middle me-1" :class="{
                      'mdi-trending-down': actualDealForSelling ? actualDealForSelling.profit.percentage < 0 : false,
                      'mdi-trending-up': actualDealForSelling ? actualDealForSelling.profit.percentage >= 0: false,
                    }"></i>
                    </span>
                  </p>
                  <div class="card bg-light overflow-hidden">
                    <div class="card-body">
                      <div class="d-flex">
                        <div class="flex-grow-1">
                          <h6 class="mb-0"><b class="text-danger"></b>{{ $t("t-info-will-be-updated-in")}}</h6>
                        </div>
                        <div class="flex-shrink-0">
                          <h6 class="mb-0">{{ this.sellingPriceUpdateTimer }} {{ $t("t-seconds-left")}}</h6>
                        </div>
                      </div>
                    </div>
                    <div class="progress bg-soft-danger rounded-0">
                      <div class="progress-bar bg-danger" role="progressbar" id="priceUpdateProgressBar" :style="{width: (100 - this.sellingPriceUpdateTimer * 20) + '%'}" aria-valuenow="{{ this.sellingPriceUpdateTimer }}" aria-valuemin="0" aria-valuemax="5"></div>
                    </div>
                  </div>
                  <div class="hstack gap-2 justify-content-center">
                    <b-button size="md" variant="success" @click="closeDeal(this.actualDealForSelling)">
                      <span v-if="this.actualDealForSelling.market_type === 'spot'">{{ $t("t-sell")}}</span>
                      <span v-if="this.actualDealForSelling.market_type === 'futures'">{{ $t("t-close-button")}}</span>
                    </b-button>
                  </div>
                </div>
        </b-modal>
        <b-modal
            v-model="openModalDealSettings"
            ref="dealModal"
            id="closeActiveDealModal"
            hide-footer
            class="modal fade close-active-deal-modal"
            :title='$t("t-deal-settings")'
        >
          <form v-on:submit.prevent="updateDealSettings">
          <div class="mt-4" v-if="this.actualSettingsForDeal">
            <h2 class="text-center">
              <img :src="actualSettingsForDeal ? actualSettingsForDeal.coin.image_path : ''" alt="" class="avatar-xxs me-2" />
              <span>{{ actualSettingsForDeal ? actualSettingsForDeal.coin.title : '' }}</span>
            </h2>
            <div class="card shadow-none overflow-hidden">
              <div class="card-body">
                <div class="col-xxl-12">
                  <div>
                    <label for="max_safety_orders" class="form-label">{{ $t('t-order-history-averaging-count') }}</label>
                    <input type="number" required class="form-control" id="max_safety_orders" v-model="form.max_safety_orders"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="col-lg-12">
                <div class="hstack gap-2 justify-content-center">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t('t-close-button') }}</button>
                  <button type="submit" class="btn btn-primary">{{ $t('t-update-button') }}</button>
                </div>
              </div>
            </div>
          </div>
          </form>
        </b-modal>
      </div>
    </div>
  </div>

</template>
